const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "地图名称",
                prop: "MC",
                minWidth: "180"
            }, {
                label: "URL",
                prop: "URL",
                minWidth: "180"
            }, {
                label: "默认中心点",
                prop: "ZXD",
                minWidth: "120"
            }, {
                label: "默认缩放级别",
                prop: "ZSJB",
                minWidth: "120"
            }, {
                label: "地图类型",
                prop: "MAPTYPE_DESC",
                minWidth: "120"
            }, {
                label: "类别",
                prop: "SERVICETYPE",
                minWidth: "120"
            }, {
                label: "备注",
                prop: "REMARK",
                minWidth: "120"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        // dateData: [{
        //     placeholder: "请选择开始时间",
        //     value: 'startTime',
        //     operateType: 'search',
        //     isShow: true
        // }, {
        //     placeholder: "请选择结束时间",
        //     value: 'endTime',
        //     operateType: 'search',
        //     isShow: true
        // }]
    },
    snButton: {
        buttonData: [{
            isShow: true,
            btnType: 'button',
            operateType: 'buttonNew',
            name: '新建',
            round: true,
            backColor: '#28ccd9',
        }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };